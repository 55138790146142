<template>
    <section class="schedule-NY" v-show="isOpen">
        <div class="schedule-NY__close" @click="closeInfo">
            &times;
        </div>
        <h2 class="schedule-NY__title">
            Расписание групп в Новый год
        </h2>
        <div class="schedule-NY__block">
            <div class="schedule-NY__name">Феникс и Маяк</div>
            <div class="schedule-NY__info">1 января групп нет, с 2 января группы буду <router-link to="/timetable">по расписанию</router-link></div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Schedule",
    data(){
        return{
            isOpen: false,
        }
    },
    mounted(){
        // console.log(localStorage.getItem('isOpen'));
        // if(localStorage.getItem('isOpen') !== null){
        //     this.isOpen = false;
        // }else{
        //     this.isOpen = false;
        // }
        localStorage.removeItem('isOpen')
    },
    methods:{
        closeInfo(){
            this.isOpen = false;
            localStorage.setItem('isOpen', 'false');
        }
    }
}
</script>

<style lang="scss">
.schedule-NY{
    position: fixed;
    // top: 0;
    left: 1rem;
    bottom: 1rem;
    width: fit-content;
    background: #fff;
    padding: 20px;
    z-index: 100;

    &__title{
        margin-top: 0;
        margin-bottom: 16px;

    }

    &__close{
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
}

@media screen and (max-width:850px) {
    .schedule-NY{
        bottom: 50px;
        &__close{
            left: 0;
            right: auto;
            
        }
    }
}
</style>